import React, { useState, useRef, memo, useEffect } from "react"
import styled from "styled-components"
import { useInterval } from "../lib/util"

// children must be an array
// other values must be implemented
// if one want a controlled component
const Reviews = memo(({ children, interval }) => {
  const { length } = children

  const [active, setActive] = useState(0)
  const [direction, setDirection] = useState(1)
  const [eventStart, setEventStart] = useState(false)
  const styledContainerRef = useRef(null)
  const styledTrackRef = useRef(null)

  const next = () => {
    setActive((p) => (p === length - 1 ? 0 : p + 1))
    setDirection(1)
  }

  const prev = () => {
    setActive((p) => {
      return p === 0 ? length - 1 : p - 1
    })
    setDirection(-1)
  }

  useEffect(() => {
    let pos1 = 0
    let pos3 = 0
    let slidePosi = 0

    if (
      styledContainerRef.current &&
      styledTrackRef.current &&
      styledTrackRef.current.firstChild
    ) {
      styledContainerRef.current.addEventListener("mousedown", (event) => {
        dragMouseDown(event)
      })
    }
    const dragMouseDown = (e) => {
      e = e || window.event
      e.preventDefault()

      pos3 = e.clientX

      styledTrackRef.current.addEventListener("mousemove", (event) => {
        dragMouse(event)
      })

      document.onmouseup = (event) => elementDragEnd(event)
    }

    const elementDragEnd = (e) => {
      e = e || window.event
      e.preventDefault()
      pos1 = e.clientX

      if (pos1 > 0) {
        if (pos1 - pos3 > 10) {
          prev()
        }
        if (pos3 - pos1 > 10) {
          next()
        }
      }
      styledTrackRef.current.removeEventListener("mousemove", (event) => {
        dragMouse(event)
      })
    }
    const dragMouse = (e) => {
      e = e || window.event
      e.preventDefault()
      slidePosi = e.clientX

      styledTrackRef.current.style.transform = `translateX(${
        slidePosi - pos3
      }px)`
    }

    return () => {
      document.onmouseup = null

      styledContainerRef.current.removeEventListener("mousedown", (event) => {
        dragMouseDown(event)
      })
      styledTrackRef.current.removeEventListener("mousemove", (event) => {
        dragMouse(event)
      })
    }
  }, [eventStart])

  useInterval(next, interval)

  return (
    <Container
      onMouseOver={(event) => {
        event.preventDefault()
        setEventStart(true)
      }}
      ref={styledContainerRef}
    >
      <StyledTrack
        key={active}
        custom={direction}
        styledTrackActive={active}
        ref={styledTrackRef}
        length={length}
      >
        {children[active]}
      </StyledTrack>
      <Dots
        className="dots"
        items={children}
        active={active}
        setActive={setActive}
      />
    </Container>
  )
})

export default Reviews

const Dots = ({ items, active, setActive, className }) => {
  return (
    <DotWrap className={className}>
      {items.map((item, i) => (
        <Dot key={i} onClick={() => setActive(i)} active={active === i} />
      ))}
    </DotWrap>
  )
}

const DotWrap = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  left: 0;
  right: 0;
  justify-content: center;
`
const Dot = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ active }) => (active ? "red" : "#d4d4d4")};
  border-radius: 200px;
  cursor: pointer;
  margin: 5px;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateY(-3px);
  }
`

const StyledTrack = styled.div`
  display: grid;
  z-index: 10;

  width: ${({ length }) => length * 100}%;
  grid-template-columns: repeat(${({ length }) => length}, 1fr);
  cursor: grab;
  &:active {
    cursor: grabbing;
  }

  animation: ${({ styledTrackActive }) =>
    styledTrackActive
      ? "slideIn 800ms ease-in-out"
      : "slideOut 800ms ease-in-out"};
  transform-origin: top center;

  @keyframes slideIn {
    0% {
      opacity: 0;
      z-index: 0;
    }
    100% {
      opacity: 1;
      z-index: 1;
    }
  }

  @keyframes slideOut {
    0% {
      opacity: 1;
      z-index: 1;
    }
    100% {
      opacity: 0;
      z-index: 0;
    }
  }
  .reviewDivheights {
    transform-origin: 100% 100%;
  }
`

const Container = styled.div`
  overflow: hidden;
  padding: 60px 0;
  .dots > div {
    width: 20px;
    height: 20px;
    margin: 8px;
  }
`
